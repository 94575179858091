import prod from './prod';

export default Object.assign({}, prod, {
  ENV: 'testing',
  STRIPE_DEVELOPMENT_MODE: true,
  BASE_URL: 'https://api-staging-internal.mozio.com',
  BASE_URL_V2: 'https://api-staging-internal.mozio.com/v2',
  BASE_URL_PRICING: `https://api-staging-internal.mozio.com.mozio.com/v2/gotransit`,
  BASE_URL_RESERVATIONS: `https://api-staging-internal.mozio.com.mozio.com/v2/gotransit`,
  BASE_URL_PAYMENTS: `https://api-staging-internal.mozio.com.mozio.com/v2/gotransit`,
  BASE_URL_SEARCH: `https://api-staging-internal.mozio.com.mozio.com/v2/gotransit`,
  BASE_URL_GOCORPS: `https://api-staging-internal.mozio.com.mozio.com/v2/gotransit`,
  AUTH_TOKEN_ID: 'mozio-auth-token',
  MOZIO_API_KEY: 'c09cc3d01bbe22cb5d7afab4f82c8f3f',
  STRIPE_PUBLISHABLE_KEY: {
    EUR: 'pk_test_LIDpOuutgjOS5pcZSPOq4KxN',
    USD: 'pk_GaP77b4VZV3ff1CR9v8SehYbIodxC',
    GBP: 'pk_test_LIDpOuutgjOS5pcZSPOq4KxN',
  },
  GOOGLE_ANALYTICS_KEY: 'UA-34281435-2',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDR0gzMBJ5RkpeLdNPucYyCzOcm7EQ7jyY',
  TAG_MANAGER_KEY: 'GTM-KMK47P',
  ZENDESK_ENABLED: true,
  GOOGLE_ANALYTICS_KEY_PARTNER: '',
  SHOW_TICKET_PASSES_SELECTION: true,
  EXPERIMENTS_ENABLED: false,
});
